// import { findLocalItems } from "../utils/util";
import Cookies from "js-cookie";
import {
  getCurrentSession,
  getCurrentAuthenticatedUser,
} from "../services/auth.service";

const deployEnvironment = process.env.REACT_APP_DEPLOY_ENVIRONMENT;

export async function fetchPatients() {
  const FORM_API_URL = process.env.REACT_APP_FORM_API_URL + "/series";

  // ensure fresh tokens
  const session = await getCurrentSession();

  // if no user logged in
  if (session == null) {
    var strClientAccessToken = Cookies.get("ClientAccessToken");
  } else strClientAccessToken = session.accessToken.jwtToken;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    accesstoken: strClientAccessToken,
  };

  // for testing  const newFormAPIPatients = [];

  const newFormAPIPatients = await fetch(FORM_API_URL, {
    method: "GET",
    headers: {
      ...headers,
    },
    /*    credentials: 'include' // include cookies in the request */
  })
    .then(handleErrors)
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
      return error;
    });

  if (
    newFormAPIPatients instanceof Error /* || oldAPIPatients instanceof Error */
  ) {
    return [];
  }

  const patients = [...newFormAPIPatients];

  const user = await getCurrentAuthenticatedUser();

  const clinicName = user?.attributes?.["custom:clinicName"];

  const hasMatchingWord = (str1, str2) => {
    const words1 = str1?.toLowerCase().split(" ");
    const words2 = str2?.toLowerCase().split(" ");

    return words1?.some((word) => words2?.includes(word));
  };

  console.log(
    "user",
    user,
    "clinicName = deployEnvironment",
    hasMatchingWord(clinicName, deployEnvironment)
  );

  // only admin-confirmed user AND
  // non-SSO clinic users
  // should only see and be able to filter authPatients
  // authPatients are all patients for user's clinic (matched to Amplify deployEnvironment) or
  // learners should only see their own studies for their clinic (matched to Amplify deployEnvironment)
  // educators can see all learners studies
  // all patients with an immutable value (e.g. PatientID) that matches a user custom attribute (e.g. signatureName)
  // non-clinic / non-demo (e.g. external) users will not see studies with no patientID

  // demo environment users are same as non-demo environment users

  const authPatients = patients.filter(
    (patient) =>
      user?.attributes["custom:adminConfirmed"] &&
      ((hasMatchingWord(clinicName, deployEnvironment) &&
        ((deployEnvironment !== "sso" &&
          deployEnvironment !== "demo" &&
          deployEnvironment !== "prime" &&
          (!patient.LearnerID ||
            user?.attributes["custom:role"] === "Educator" ||
            // hide test reports of other learners from the current Learner
            !(patient.LearnerID !== user?.attributes["custom:learnerID"]))) ||
          ((deployEnvironment === "sso" ||
            deployEnvironment === "demo" ||
            deployEnvironment === "prime") &&
            (user?.attributes["custom:role"] !== "cardiologist" ||
              !(
                patient.studyStatus === "In progress" &&
                !user?.attributes["custom:signatureName"].includes(
                  patient.PhysiciansOfRecord?.slice(-4)
                )
              ))))) ||
        (patient.PatientID &&
          patient.PatientID === user?.attributes["custom:signatureName"]))
  );

  console.log("authPatients", authPatients);

  // Source: https://flaviocopes.com/how-to-sort-array-of-objects-by-property-javascript/
  // Sort based on SeriesDate then by SeriesTime
  const sortedPatients = authPatients.sort((a, b) =>
    a.SeriesDate > b.SeriesDate
      ? 1
      : a.SeriesDate === b.SeriesDate
      ? a.SeriesTime > b.SeriesTime
        ? 1
        : -1
      : -1
  );
  sortedPatients.reverse();

  return Promise.resolve(
    sortedPatients.map((patient) => {
      // calculate the slug
      patient.slug = patient.SeriesInstanceUID;
      patient.studyIUID = patient.StudyInstanceUID;

      return patient;
    })
  );
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
